import './App.css';
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <>
    <Navbar/>
    </>
  );
}

export default App;
