import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles.css";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineShoppingBag } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";

import logo from "../../Asserts/logo.png";
import img1 from "../../Asserts/img1.png";
import img2 from "../../Asserts/img2.png";
import img3 from "../../Asserts/img3.png";
import img4 from "../../Asserts/img4.png";
import img5 from "../../Asserts/img5.png";
import img6 from "../../Asserts/img6.jpeg";
import img7 from "../../Asserts/img7.png";
import img8 from "../../Asserts/img8.png";
import img9 from "../../Asserts/img9.jpeg";
import img10 from "../../Asserts/img10.png";
// import img11 from "../../Asserts/img11.jpeg";

const Navbar = () => {
  useEffect(() => {
    const text1 = document.querySelector(".animate-text1");
    const text2 = document.querySelector(".animate-text2");

    function animateText1() {
      text1.classList.add("active");
      setTimeout(() => {
        text1.classList.remove("active");
        text1.classList.add("hidden");
        text2.classList.remove("hidden");
        animateText2();
      }, 2000); // Duration text1 is visible
    }

    function animateText2() {
      text2.classList.add("active");
      setTimeout(() => {
        text2.classList.remove("active");
        text2.classList.add("hidden");
        text1.classList.remove("hidden");
        animateText1();
      }, 2000); // Duration text2 is visible
    }

    // Initial setup
    text2.classList.add("hidden");
    animateText1();
  }, []);

  // const scrollContainerRef = useRef(null);

  // const scrollLeft = () => {
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollBy({ left: -500, behavior: "smooth" });
  //   }
  // };
  const gotoWhatsapp = () => {
    const message = encodeURIComponent(
      "Hello, I'm interested in the Indori Poha Masala."
    );
    const url = `https://wa.me/9441402498?text=${message}`;
    window.location.href = url;
  };

  // const scrollRight = () => {
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
  //   }
  // };


  const products = [
    {
      id: 1,
      src: "https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049",
      alt: "Indori Poha Masala",
      name: "Indori Poha Masala",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 2,
      src: "https://rootedpeepul.com/cdn/shop/products/1_4abdf02c-2a5d-4cbc-9f00-83087dcb86fd_305x.png?v=1643470615",
      alt: "Bombay Sandwich Masala",
      name: "Bombay Sandwich Masala",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 3,
      src: "https://rootedpeepul.com/cdn/shop/products/1_6d25c8d4-f754-48a6-bf72-67f00300de7d_1024x1024@2x.png?v=1664875460",
      alt: "Mummy wala Khichdi Masala",
      name: "Mummy wala Khichdi Masala",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 4,
      src: "https://rootedpeepul.com/cdn/shop/products/1_a19035c2-d649-45ad-aff2-56946b5ace56_720x.png?v=1643508247",
      alt: "Delhi Butter Chicken Masala",
      name: "Delhi Butter Chicken Masala",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 5,
      src: "https://rootedpeepul.com/cdn/shop/products/1_af896dac-8b99-4c8f-a75b-cb6d96326d1a_1024x1024@2x.png?v=1664874015",
      alt: "Smoked Tandoori Chai Masala",
      name: "Smoked Tandoori Chai Masala",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 6,
      src: "https://rootedpeepul.com/cdn/shop/products/1_a19035c2-d649-45ad-aff2-56946b5ace56_1024x1024@2x.png?v=1643508247",
      alt: "Delhi Butter Chicken Masala",
      name: "Delhi Butter Chicken Masala",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 7,
      src: "https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049",
      alt: "Indori Poha Masala",
      name: "Indori Poha Masala",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 8,
      src: "https://media.istockphoto.com/id/1408296553/photo/peanut-oil-in-a-glass-jug-and-raw-peeled-groundnut-in-a-glass-bowl-over-gray-background.jpg?s=612x612&w=0&k=20&c=smSLbST6tLTBMhywWK9zwqw2sfahMefjDGdsWBjT704=",
      alt: " Peanut Oil in Glass",
      name: "Peanut Oil in Glass",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 8,
      src: "https://cafesano.com/wp-content/uploads/2015/07/incorporating-nuts-seeds-oil-in-mediterranean-diet.jpg",
      alt: "Nuts, Seeds, and Olive Oil",
      name: "Nuts, Seeds, and Olive Oil",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 8,
      src: "https://cafesano.com/wp-content/uploads/2015/07/incorporating-nuts-seeds-oil-in-mediterranean-diet.jpg",
      alt: "Nuts, Seeds, and Olive Oil",
      name: "Nuts, Seeds, and Olive Oil",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 8,
      src: "https://cafesano.com/wp-content/uploads/2015/07/incorporating-nuts-seeds-oil-in-mediterranean-diet.jpg",
      alt: "Nuts, Seeds, and Olive Oil",
      name: "Nuts, Seeds, and Olive Oil",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    {
      id: 8,
      src: "https://cafesano.com/wp-content/uploads/2015/07/incorporating-nuts-seeds-oil-in-mediterranean-diet.jpg",
      alt: "Nuts, Seeds, and Olive Oil",
      name: "Nuts, Seeds, and Olive Oil",
      oldPrice: "Rs. 225.00",
      newPrice: "Rs. 195.00"
    },
    
  ];
  
  const scrollContainerRef = useRef(null);

  

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -238,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    
    if (scrollContainerRef.current) {
      console.log(scrollContainerRef.current.clientWidth)
      scrollContainerRef.current.scrollBy({
        left: 235,
        behavior: 'smooth'
      });
    }
  };


  

  return (

    
    <>
      <div className="promo-container">
        <h5 className="animate-text1 m-0">
          Free delivery on all prepaid orders
        </h5>
        <h5 className="animate-text2 m-0">
          Flat 10% off on first order. Use code WELCOME10 on orders above
          Rs.299.
        </h5>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light md-5">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={logo} alt="Logo" style={{ height: "80px" }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  New Lanches
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Spices & Blends
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Super Savers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Nuts & Oils
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Specials
                </a>
              </li>
            </ul>
            <div className="d-flex">
              <button className="btn btn-outline-primary mx-1" type="button">
                {/* <i className="bi bi-search"></i> */}
                <CiSearch />
              </button>
              <button className="btn btn-outline-primary mx-1" type="button">
                {/* <i className="bi bi-person"></i> */}
                <IoPersonOutline />
              </button>
              <button className="btn btn-outline-primary mx-1" type="button">
                {/* <i className="bi bi-bag"></i> */}
                <MdOutlineShoppingBag />
              </button>
            </div>
          </div>
        </div>
      </nav>


      <div className="contecnt">

      <main className="content mt-5">
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={img1}
                className="d-block w-100 top-img"
                alt="Third slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img2}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img3}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img4}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img5}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img6}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img7}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img8}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img9}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img10}
                className="d-block w-100 top-img"
                alt="Fourth slide"
              />
            </div>
            {/* <div className="carousel-item">
        <img src={img11} className="d-block w-100 top-img" alt="Fourth slide" />
      </div> */}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </main>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-8 col-md-10 col-sm-12">
            <h3 className="mb-4">
              Premium Indian Spices - Freshest, Cleanest, and Most Aromatic
              Spices
            </h3>
            <p className="mb-4">
              Looking for the best place to buy premium spices online? Look no
              further than Rooted Peepul! We bring the best of them to your
              doorstep. Our chemical-free spices are hand-selected and freshly
              stone-grounded to give you the most flavorful experience ever!
            </p>
            <h4 className="mb-3">WHY ROOTED PEEPUL?</h4>
            <ul className="list-unstyled">
              <li>✔ Less Chilli More Spices</li>
              <li>✔ No Colour or Preservatives</li>
              <li>✔ Plastic-Free Packaging</li>
              <li>✔ Stone-Grounded</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container my-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-start flex-wrap">
            <img
              src="https://rootedpeepul.com/cdn/shop/files/taste_enhancers_360x.jpg?v=1705135798"
              className="img-fluid me-3 mb-3"
              alt="Taste Enhancers"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <img
              src="https://rootedpeepul.com/cdn/shop/files/5_360x.png?v=1674542098"
              className="img-fluid me-3 mb-3"
              alt="Image 5"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <img
              src="https://rootedpeepul.com/cdn/shop/files/7_360x.png?v=1674542176"
              className="img-fluid mb-3"
              alt="Image 7"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div className="container text-center my-5">
        <h1 className="display-4" style={{ fontFamily: "cursive" }}>
          Shop By Category
        </h1>

        <div className="d-flex align-items-center justify-content-center my-4">
          <hr
            className="flex-fill"
            style={{
              borderTop: "1px solid rgb(228, 248, 154)",
              margin: "0",
              padding: "0",
              maxWidth: "100px",
            }}
          />
          <h5 className="mx-3" style={{ color: "rgb(82, 68, 68)" }}>
            THE BEST COLLECTION
          </h5>
          <hr
            className="flex-fill"
            style={{
              borderTop: "1px solid rgb(251, 253, 146)",
              margin: "0",
              padding: "0",
              maxWidth: "100px",
            }}
          />
        </div>
      </div>

      <div className="container-fluid py-1 ">
        <div className="row g-4">
          <div className="col-md-3">
            <div className="spices d-flex align-items-center justify-content-center">
              Spices & Blends
            </div>
          </div>
          <div className="col-md-3">
            <div className="turmaric d-flex align-items-center justify-content-center">
              Immunity
            </div>
          </div>
          <div className="col-md-3">
            <div className="savers d-flex align-items-center justify-content-center">
              Super Savers
            </div>
          </div>
          <div className="col-md-3">
            <div className="small d-flex align-items-center justify-content-center">
              Small and Perfect
            </div>
          </div>
        </div>
      </div>

      {/* <div className="text-center mt-4">
        <h1 className="display-3 font-italic mb-3 font-cursive">
          Featured Products
        </h1>
        <div className="d-flex slider-container">
          <IoIosArrowDropleft
            className="bg-transparent icons  "
            onClick={scrollLeft}
          />

          <div className="d-flex scroll-content mx-md-5" ref={scrollContainerRef}>
            <div className="d-flex flex-column align-items-center my-3 mx-3">
              <div
                className="border rounded shadow-sm"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
                  alt="Indori Poha Masala"
                  className="img-fluid rounded"
                  style={{ height: "197px", width: "197px" }}
                />
              </div>
              <h6 className="mt-2 text-center">
                Indori Poha Masala | 100% Natural | 75gms
              </h6>
              <del>
                <b className="text-danger">Rs. 225.00</b>
              </del>
              <p className="text-center">Rs. 195.00</p>
              <div className="pt-2">
                <button
                  onClick={gotoWhatsapp}
                  className="btn btn-dark text-light"
                >
                  Add to cart
                </button>
              </div>
            </div>

            <div className="d-flex flex-column align-items-center my-3 mx-2">
              <div
                className="border rounded shadow-sm"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src="https://rootedpeepul.com/cdn/shop/products/1_4abdf02c-2a5d-4cbc-9f00-83087dcb86fd_305x.png?v=1643470615"
                  alt="Indori Poha Masala"
                  className="img-fluid rounded"
                  style={{ height: "197px", width: "197px" }}
                />
              </div>
              <h6 className="mt-2 text-center">
                Indori Poha Masala | 100% Natural | 75gms
              </h6>
              <del>
                <b className="text-danger">Rs. 225.00</b>
              </del>
              <p className="text-center">Rs. 195.00</p>
              <div className="pt-2">
                <button
                  onClick={gotoWhatsapp}
                  className="btn btn-dark text-light"
                >
                  Add to cart
                </button>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center my-3 mx-2">
              <div
                className="border rounded shadow-sm"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src="https://rootedpeepul.com/cdn/shop/products/1_1_305x.png?v=1642997770"
                  alt="Indori Poha Masala"
                  className="img-fluid rounded"
                  style={{ height: "197px", width: "197px" }}
                />
              </div>
              <h6 className="mt-2 text-center">
                Indori Poha Masala | 100% Natural | 75gms
              </h6>
              <del>
                <b className="text-danger">Rs. 225.00</b>
              </del>
              <p className="text-center">Rs. 195.00</p>
              <div className="pt-2">
                <button
                  onClick={gotoWhatsapp}
                  className="btn btn-dark text-light"
                >
                  Add to cart
                </button>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center my-3 mx-2">
              <div
                className="border rounded shadow-sm"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
                  alt="Indori Poha Masala"
                  className="img-fluid rounded"
                  style={{ height: "197px", width: "197px" }}
                />
              </div>
              <h6 className="mt-2 text-center">
                Indori Poha Masala | 100% Natural | 75gms
              </h6>
              <del>
                <b className="text-danger">Rs. 225.00</b>
              </del>
              <p className="text-center">Rs. 195.00</p>
              <div className="pt-2">
                <button
                  onClick={gotoWhatsapp}
                  className="btn btn-dark text-light"
                >
                  Add to cart
                </button>
              </div>
            </div>

            <div className="d-flex flex-column align-items-center my-3 mx-2">
              <div
                className="border rounded shadow-sm"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src="https://rootedpeepul.com/cdn/shop/products/1_6d25c8d4-f754-48a6-bf72-67f00300de7d_305x.png?v=1664875460"
                  alt="Indori Poha Masala"
                  className="img-fluid rounded"
                  style={{ height: "197px", width: "197px" }}
                />
              </div>
              <h6 className="mt-2 text-center">
                Indori Poha Masala | 100% Natural | 75gms
              </h6>
              <del>
                <b className="text-danger">Rs. 225.00</b>
              </del>
              <p className="text-center">Rs. 195.00</p>
              <div className="pt-2">
                <button
                  onClick={gotoWhatsapp}
                  className="btn btn-dark text-light"
                >
                  Add to cart
                </button>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center my-3 mx-2">
              <div
                className="border rounded shadow-sm"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src="https://rootedpeepul.com/cdn/shop/products/1_6d25c8d4-f754-48a6-bf72-67f00300de7d_305x.png?v=1664875460"
                  alt="Indori Poha Masala"
                  className="img-fluid rounded"
                  style={{ height: "197px", width: "197px" }}
                />
              </div>
              <h6 className="mt-2 text-center">
                Indori Poha Masala | 100% Natural | 75gms
              </h6>
              <del>
                <b className="text-danger">Rs. 225.00</b>
              </del>
              <p className="text-center">Rs. 195.00</p>
              <div className="pt-2">
                <button
                  onClick={gotoWhatsapp}
                  className="btn btn-dark text-light"
                >
                  Add to cart
                </button>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center my-3 mx-2">
              <div
                className="border rounded shadow-sm"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src="https://rootedpeepul.com/cdn/shop/products/1_8e69f0f5-ed31-4f3d-9f5b-172b7e2614a5_305x.png?v=1643508600"
                  alt="Indori Poha Masala"
                  className="img-fluid rounded"
                  style={{ height: "197px", width: "197px" }}
                />
              </div>
              <h6 className="mt-2 text-center">
                Indori Poha Masala | 100% Natural | 75gms
              </h6>
              <del>
                <b className="text-danger">Rs. 225.00</b>
              </del>
              <p className="text-center">Rs. 195.00</p>
              <div className="pt-2">
                <button
                  onClick={gotoWhatsapp}
                  className="btn btn-dark text-light"
                >
                  Add to cart
                </button>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center my-3 mx-2">
              <div
                className="border rounded shadow-sm"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src="https://rootedpeepul.com/cdn/shop/products/1_a19035c2-d649-45ad-aff2-56946b5ace56_305x.png?v=1643508247"
                  alt="Indori Poha Masala"
                  className="img-fluid rounded"
                  style={{ height: "197px", width: "197px" }}
                />
              </div>
              <h6 className="mt-2 text-center">
                Indori Poha Masala | 100% Natural | 75gms
              </h6>
              <del>
                <b className="text-danger">Rs. 225.00</b>
              </del>
              <p className="text-center">Rs. 195.00</p>
              <div className="pt-2">
                <button
                  onClick={gotoWhatsapp}
                  className="btn btn-dark text-light"
                >
                  Add to cart
                </button>
              </div>
            </div>
          </div>
          <IoIosArrowDropright
            className="bg-transparent icons2"
            onClick={scrollRight}
          />
        </div>
      </div> */}





<div className="text-center mt-4 ">
      <h1 className="display-3 font-italic mb-3 font-cursive">Featured Products</h1>
      <div style={{ position: 'relative'  }} >
        <IoIosArrowDropleft
          style={{
            position: 'absolute',
            top: '30%',
            left: 0,
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            fontSize: '4rem',
            zIndex: 1
          }}
          onClick={scrollLeft}
        />
        <div
          className="d-flex products"
          style={{
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            scrollBehavior: 'smooth',
            padding: '0 3rem' // Adjust padding for spacing between arrows and content
          }}
          ref={scrollContainerRef}
        >
          {products.map(product => (
            <div key={product.id} style={{
              display: 'inline-block',
              width: '200px',
              margin: '0 1rem',
              textAlign: 'center'
            }}>
              <div style={{
                border: '1px solid #ddd',
                borderRadius: '0.25rem',
                boxShadow: '0 0 0.5rem rgba(0,0,0,0.1)',
                overflow: 'hidden',
                width: '200px',
                height: '200px'
              }}>
                <img
                  src={product.src}
                  alt={product.alt}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
              </div>
              <h6 style={{ marginTop: '0.5rem' }}>{product.name}</h6>
              <del style={{ color: 'red' }}>
                <b>{product.oldPrice}</b>
              </del>
              <p>{product.newPrice}</p>
              <div style={{ paddingTop: '0.5rem' }}>
                <button
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    border: 'none',
                    padding: '0.5rem 1rem',
                    cursor: 'pointer'
                  }}
                  onClick={gotoWhatsapp}
                >
                  Add to cart
                </button>
              </div>
            </div>
          ))}
        </div>
        <IoIosArrowDropright
          style={{
            position: 'absolute',
            top: '30%',
            right: 0,
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            fontSize: '4rem',
            zIndex: 1
          }}
          onClick={scrollRight}
        />
      </div>
    </div>

      <div class="bg d-flex align-items-center justify-content-center text-center py-5">
        <div class="container">
          <h1 class="text-dark mb-3">Our History - A LITTLE STORY</h1>
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <p class="text-dark">
                Putting healthy eating at the forefront of urban lifestyle while
                caring for people and planet alike.
              </p>
              <p class="text-dark">
                What can be better than the joy of touching that perfect horizon
                of Health and Taste.
              </p>
              <p class="text-dark">
                The ‘quest for purity’ and its amalgamation with the
                ‘satisfaction of taste buds’ are the driving forces behind
                Rooted Peepul.
              </p>
              <p class="text-dark">
                The vision and the process behind Rooted Peepul has been simple:
                Keep going back to the drawing board, or in our case the
                kitchen, till the time we are not fully satisfied with each and
                every aspect of our various healthy lattes, spices, and spice
                mixes we are selling.
              </p>
              <button class="btn btn-primary mt-4">Know More</button>
            </div>
          </div>
        </div>
      </div>

      <div class="container text-center my-5">
        <h1 class="mb-4">What They're Saying</h1>

        <div class="d-flex align-items-center justify-content-center mb-4">
          <hr class="w-25" />
          <h5 class="mx-3 text-darkgreen">WE LOVE OUR CLIENTS</h5>
          <hr class="w-25" />
        </div>

        <div class="row">
          <div class="col-lg-4 mb-4">
            <h1>,,</h1>
            <p class="text-darkgreen">
              This IS a truly ‘naturally good’ product !
            </p>
            <p>
              ONE can taste the authentic spiced turmeric AND there’s NO acidic
              OR artificial flavour neither IS it sweetened, apt FOR diabetics
              AND diet conscious ppl too..
            </p>
            <p class="text-darkgreen mt-3">-Prackriti</p>
          </div>

          <div class="col-lg-4 mb-4">
            <h1>,,</h1>
            <p class="text-darkgreen">
              Lovely product. Very tasty AND my kid loves it.
            </p>
            <p>
              Winter IS coming AND the little ONE was waking WITH sniffles. 5
              nights drinking this before sleep and waking up EVERY morning
              fresh with no more sniffle.
            </p>
            <p class="text-darkgreen mt-3">-Kaajari Tiwari</p>
          </div>

          <div class="col-lg-4 mb-4">
            <h1>,,</h1>
            <p class="text-darkgreen">
              Writing AFTER 3 weeks of use. The product IS very well balanced
              WITH the RIGHT amount of spices.
            </p>
            <p>
              Has become part of my daily diet. Ideal FOR this TIME WHEN
              immunity needs TO be high. The glass jar keeps it.
            </p>
            <p class="text-darkgreen mt-3">-Angad Singh</p>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <h1 className="text-center" style={{ fontFamily: "cursive" }}>
          Latest Our Posts
        </h1>
        <div className="row mt-4">
          <div className="col-md-4 mb-4">
            <div className="card">
              <img
                src="https://rootedpeepul.com/cdn/shop/articles/Untitled_design_1_540x.png?v=1675238628"
                className="card-img-top"
                alt="Post 1"
              />
              <div className="card-body">
                <h4>Benefits of Preservative Free Food</h4>
                <p>
                  <strong>WHAT IS PRESERVATIVE FREE FOOD</strong>
                </p>
                <p>
                  Preservative-free food refers to edible products that do not
                  contain...
                </p>
                <button className="btn btn-primary">READ MORE</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <img
                src="https://rootedpeepul.com/cdn/shop/articles/Untitled_design_1_540x.png?v=1675238628"
                className="card-img-top"
                alt="Post 2"
              />
              <div className="card-body">
                <h4>Benefits of Preservative Free Food</h4>
                <p>
                  <strong>WHAT IS PRESERVATIVE FREE FOOD</strong>
                </p>
                <p>
                  Preservative-free food refers to edible products that do not
                  contain...
                </p>
                <button className="btn btn-primary">READ MORE</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <img
                src="https://rootedpeepul.com/cdn/shop/articles/Untitled_design_1_540x.png?v=1675238628"
                className="card-img-top"
                alt="Post 3"
              />
              <div className="card-body">
                <h4>Biryani Masala vs Garam Masala, What's The DIfference</h4>
                <p>
                  <strong>WHAT IS PRESERVATIVE FREE FOOD</strong>
                </p>
                <p>
                  Preservative-free food refers to edible products that do not
                  contain...
                </p>
                <button className="btn btn-primary">READ MORE</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <button className="btn btn-dark px-4 py-2">VIEW ALL</button>
      </div>

      <div
        className="container-fluid mt-5"
        style={{ backgroundColor: "whitesmoke" }}
      >
        {/* Quick Links Section */}
        <div className="mb-4">
          <h5 className="mt-5 pt-4 ms-5">Quick links</h5>
        </div>

        {/* Links and Email Subscription Section */}
        <div className="row mb-4">
          <div className="col-md-6 d-flex flex-wrap">
            <a className="text-muted ms-4" href="#">
              About Us
            </a>
            <a className="text-muted ms-4" href="#">
              Privacy Policy
            </a>
            <a className="text-muted ms-4" href="#">
              Refund Policy
            </a>
            <a className="text-muted ms-4" href="#">
              Shipping Policy
            </a>
            <a className="text-muted ms-4" href="#">
              Terms of Service
            </a>
            <a className="text-muted ms-4" href="#">
              Contact
            </a>
          </div>

          <div className="col-md-6 d-flex align-items-center justify-content-end">
            <input
              type="text"
              placeholder="Email address"
              className="form-control me-2"
              style={{ width: "400px" }}
            />
            <button className="btn btn-dark">Subscribe</button>
          </div>
        </div>

        <hr className="my-5 border-dark" />

        {/* Social Icons and Copyright Text */}
        <div className="d-flex justify-content-end mb-3">
          <div className="d-flex align-items-center">
            <FaYoutube className="me-3" size={40} />
            <IoLogoInstagram className="me-3" size={40} />
            <FaFacebook className="me-3" size={40} />
          </div>
        </div>

        <div className="text-end">
          <p className="mb-0">© 2024, Rooted Peepul Powered by Shopify</p>
        </div>
      </div>
      </div>
     

      {/* <div  className='d-flex slider-container'>
    <IoIosArrowDropleft className='bg-transparent icons  ' onClick={scrollLeft}/>
    
    <div className='d-flex scroll-content ' ref={scrollContainerRef}>
    <div className="d-flex flex-column align-items-center my-3 mx-2">
      <div className="border rounded shadow-sm" style={{ width: '200px', height: '200px' }}>
        <img
          src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
          alt="Indori Poha Masala"
          className="img-fluid rounded"
          style={{ height: '197px', width: '197px' }}
        />
      </div>
      <h6 className="mt-2 text-center">Indori Poha Masala | 100% Natural | 75gms</h6>
      <del>
        <b className="text-danger">Rs. 225.00</b>
      </del>
      <p className="text-center">Rs. 195.00</p>
      <div className="pt-2">
        <button
          onClick={gotoWhatsapp}
          className="btn btn-dark text-light"
        >
          Add to cart
        </button>
      </div>
    </div>

    <div className="d-flex flex-column align-items-center my-3 mx-2">
      <div className="border rounded shadow-sm" style={{ width: '200px', height: '200px' }}>
        <img
          src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
          alt="Indori Poha Masala"
          className="img-fluid rounded"
          style={{ height: '197px', width: '197px' }}
        />
      </div>
      <h6 className="mt-2 text-center">Indori Poha Masala | 100% Natural | 75gms</h6>
      <del>
        <b className="text-danger">Rs. 225.00</b>
      </del>
      <p className="text-center">Rs. 195.00</p>
      <div className="pt-2">
        <button
          onClick={() => alert('Add to cart')}
          className="btn btn-dark text-light"
        >
          Add to cart
        </button>
      </div>
    </div>
    <div className="d-flex flex-column align-items-center my-3 mx-2">
      <div className="border rounded shadow-sm" style={{ width: '200px', height: '200px' }}>
        <img
          src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
          alt="Indori Poha Masala"
          className="img-fluid rounded"
          style={{ height: '197px', width: '197px' }}
        />
      </div>
      <h6 className="mt-2 text-center">Indori Poha Masala | 100% Natural | 75gms</h6>
      <del>
        <b className="text-danger">Rs. 225.00</b>
      </del>
      <p className="text-center">Rs. 195.00</p>
      <div className="pt-2">
        <button
          onClick={() => alert('Add to cart')}
          className="btn btn-dark text-light"
        >
          Add to cart
        </button>
      </div>
    </div>
    <div className="d-flex flex-column align-items-center my-3 mx-2">
      <div className="border rounded shadow-sm" style={{ width: '200px', height: '200px' }}>
        <img
          src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
          alt="Indori Poha Masala"
          className="img-fluid rounded"
          style={{ height: '197px', width: '197px' }}
        />
      </div>
      <h6 className="mt-2 text-center">Indori Poha Masala | 100% Natural | 75gms</h6>
      <del>
        <b className="text-danger">Rs. 225.00</b>
      </del>
      <p className="text-center">Rs. 195.00</p>
      <div className="pt-2">
        <button
          onClick={() => alert('Add to cart')}
          className="btn btn-dark text-light"
        >
          Add to cart
        </button>
      </div>
    </div>

    <div className="d-flex flex-column align-items-center my-3 mx-2">
      <div className="border rounded shadow-sm" style={{ width: '200px', height: '200px' }}>
        <img
          src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
          alt="Indori Poha Masala"
          className="img-fluid rounded"
          style={{ height: '197px', width: '197px' }}
        />
      </div>
      <h6 className="mt-2 text-center">Indori Poha Masala | 100% Natural | 75gms</h6>
      <del>
        <b className="text-danger">Rs. 225.00</b>
      </del>
      <p className="text-center">Rs. 195.00</p>
      <div className="pt-2">
        <button
          onClick={() => alert('Add to cart')}
          className="btn btn-dark text-light"
        >
          Add to cart
        </button>
      </div>
    </div>
    <div className="d-flex flex-column align-items-center my-3 mx-2">
      <div className="border rounded shadow-sm" style={{ width: '200px', height: '200px' }}>
        <img
          src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
          alt="Indori Poha Masala"
          className="img-fluid rounded"
          style={{ height: '197px', width: '197px' }}
        />
      </div>
      <h6 className="mt-2 text-center">Indori Poha Masala | 100% Natural | 75gms</h6>
      <del>
        <b className="text-danger">Rs. 225.00</b>
      </del>
      <p className="text-center">Rs. 195.00</p>
      <div className="pt-2">
        <button
          onClick={() => alert('Add to cart')}
          className="btn btn-dark text-light"
        >
          Add to cart
        </button>
      </div>
    </div>
    <div className="d-flex flex-column align-items-center my-3 mx-2">
      <div className="border rounded shadow-sm" style={{ width: '200px', height: '200px' }}>
        <img
          src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
          alt="Indori Poha Masala"
          className="img-fluid rounded"
          style={{ height: '197px', width: '197px' }}
        />
      </div>
      <h6 className="mt-2 text-center">Indori Poha Masala | 100% Natural | 75gms</h6>
      <del>
        <b className="text-danger">Rs. 225.00</b>
      </del>
      <p className="text-center">Rs. 195.00</p>
      <div className="pt-2">
        <button
          onClick={() => alert('Add to cart')}
          className="btn btn-dark text-light"
        >
          Add to cart
        </button>
      </div>
    </div>
    <div className="d-flex flex-column align-items-center my-3 mx-2">
      <div className="border rounded shadow-sm" style={{ width: '200px', height: '200px' }}>
        <img
          src="https://thestateplate.com/cdn/shop/files/Pohamasala.jpg?v=1693375049"
          alt="Indori Poha Masala"
          className="img-fluid rounded"
          style={{ height: '197px', width: '197px' }}
        />
      </div>
      <h6 className="mt-2 text-center">Indori Poha Masala | 100% Natural | 75gms</h6>
      <del>
        <b className="text-danger">Rs. 225.00</b>
      </del>
      <p className="text-center">Rs. 195.00</p>
      <div className="pt-2">
        <button
          onClick={() => alert('Add to cart')}
          className="btn btn-dark text-light"
        >
          Add to cart
        </button>
      </div>
    </div>
   
    </div>
    <IoIosArrowDropright className='bg-transparent icons2 ' onClick={scrollRight}/>
  </div> */}
    </>
  );
};

export default Navbar;
